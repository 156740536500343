<template>
    <div class="container">
        <header class="jumbotron">
            <h3 v-if="isDeviceAccount">
                <strong>{{ currentUser.email }}</strong> <p class="bg-warning">(WARNING: you are logged in AS A DEVICE)</p>
            </h3>
            <h5 v-else>
                <strong>{{ currentUser.email }}</strong>
            </h5>
        </header>
        <p>
            <strong>Token:</strong>
            {{ currentUser.accessToken.substring(0, 20) }} ...
            {{ currentUser.accessToken.substr(currentUser.accessToken.length - 20) }}
        </p>
        <p>
            <strong>Id:</strong>
            {{ currentUser.id }}
        </p>
        <p>
            <strong>Email:</strong>
            {{ currentUser.email }}
        </p>
        <p>
            <strong>Name:</strong>
            {{ currentUser.name }}
        </p>
        <strong>Authorities:</strong>
        <ul>
            <li v-for="role in currentUser.roles" :key="role">{{ role }}</li>
        </ul>
    </div>
</template>

<script>
export default {
    name: 'Profile',
    computed: {
        currentUser() {
            return this.$store.state.auth.user;
        },
        isDeviceAccount() {
            if (this.$store.state.auth.user && this.$store.state.auth.user['roles']) {
                return this.$store.state.auth.user['roles'].includes('device');
            }

            return false;
        }
    },
    mounted() {
        if (!this.currentUser) {
            this.$router.push('/login');
        }
    }
};
</script>
